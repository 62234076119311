@mixin vertical-center($parentH) {
  margin-top: $parentH / 2;
  transform: translateY(-50%);
}
@mixin fix-parent-margin {
  overflow: hidden;
}
@mixin ab-vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@mixin clearfix {
  zoom:1;
  &:after {
   content:".";
   display:block;
   height:0;
   clear:both;
   visibility:hidden;
  }
}