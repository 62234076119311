@font-face {font-family: "iconfont";
  src: url('iconfont.eot?t=1556006752821'); /* IE9 */
  src: url('iconfont.eot?t=1556006752821#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALcAAsAAAAABqwAAAKNAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCCcAqBNIE1ATYCJAMICwYABCAFhG0HMRvcBcgekiRFAgkUoIj7GAAggmi/3+y7+4pJUo0kkyyayCRKoJKgRCnFQrdI8kx+/7umf8w2IyrJIavS5GFyx+lL04EitKwJ3dT0VOsqt2lOXx9yn7h3+ifQB/m/euddjnvRWJMmdQHGgRTQ3tRFkRVI6A1jNxE8wPsEmoZ0ydiZmAtDvgwsC8SFoUmQXwjJspOtC9WGvVncqKgXV0USuObfjz+ByKdQSUDd/vm4CkOfYF5aLS+dUUKYYByuIGEByMRxY2pXlnC4LM1UtjFwrOrgU1lSpziqTTh/HahsBQO6/JkUWPmo0gXIDGcJ2Jn0HGmgu1gqfHttyDl/myy6vFu9aSrcv4V8eVdUOFPZv1urm48er90f1ESOVqvDh/OlV/earbWso36l416amKiN1pKJ0j0iLQTdV5GqKJabhXW3lXv1qRcwJlV/Vv+eTicbkmbo9TkNw+R0MQwBlC/pPnX+yN/46EoSyvr+60UZvP8l8kD5ksH0gPrp3MFvUwzsyrEKOIocKhpzhSVZt9PQ1ETJ9HX7nGruu8dcqOu7U0/NYIakboLM7AIqWiyiqm4dTfMmD7fowWSJ3MCcLQ2h0zMK7R6QdEqRmf1ARb9/VHVGFppOo+rMFlNhFI4yLHGsInkZGQp1CXLxQlEdxFrCllhexfkoZnEjivweXzk7iV3MltgQX9ACnBNEGHXQhPYYtm2KYoyaWOEenfNYj9dLmt7kUagDhbYxmITDVIhsGWJQUC5C+3OFlc8HYZoEm4R1dIxkozAmzhgd8fPwDSAn9e6gjkd5JW6BJoDjCIRgKAcyoc3CbDYKiTXPMmEKzkOfkIjp4YW9yFC9Z3uj83cHoAlYioBTk9oF1bppggMAAAAA') format('woff2'),
  url('iconfont.woff?t=1556006752821') format('woff'),
  url('iconfont.ttf?t=1556006752821') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('iconfont.svg?t=1556006752821#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-log-out:before {
  content: "\e6be";
}

