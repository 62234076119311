@import './variable.scss';
@import './mixins.scss';
@import './iconfont.css';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* common class */
.right {
  float: right;
}
.left {
  height: 60px;
  line-height: 56px;
  float: left;
}
.flex {
  display: flex;
}
.inheritH {
  height: inherit;
}
.pointer {
  cursor: pointer;
}
.warning {
  color: $yellow-1;
}
.hot {
  color: $red-1;
}
.black-font {
  color: $black;
}
.grey-font {
  color: $thin-grey-6;
}
.import-font {
  color: $dark-1;
  font-size: 16px;
  font-weight: 400;
}
.inline-block {
  display: inline-block;
}
.link {
  color: $blue-1 !important;
  text-decoration: underline;
  cursor: pointer;
}
.not-collect {
  font-size: 20px;
  color: $thin-grey-8 !important;
  cursor: pointer;
}
.collected {
  font-size: 20px;
  color: $yellow-2 !important;
  cursor: pointer;
}
.show-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}
/* common class */

/* common component class */

.nav-menu {
  height: 46px;
  .nav-menu-item {
    float: left;
    box-sizing: border-box;
    padding: 8px 0;
    margin: 0 2px;
    height: 46px;
    line-height: 46px;
    font-size: $middle-font;
    color: $white;
    cursor: pointer;
    position: relative;
    &::after {
      @include ab-vertical-center;
      content: '';
      height: 14px;
      width: 1px;
      right: 0;
      background: rgba(255, 255, 255, 0.2);
    }
    a {
      transition: color 0s !important;
    }
    &.active {
      .menu-item-content {
        background: $white;
        color: $theme-color;
      }
      a {
        background: $white !important;
        color: $theme-color !important;
      }
    }
    .menu-item-content {
      height: 30px;
      line-height: 30px;
      padding: 0 20px;
      border-radius: 4px;
    }
  }
  .nav-sub-menu {
    .nav-sub-menu-panel {
      position: absolute;
      left: 0;
      top: 46px;
      width: 320px;
      background: $white;
      padding: 14px 20px;
      z-index: $zindex-level-1;
      border: 1px solid $thin-grey-1;
      box-shadow: 0px 4px 8px 0px rgba(67, 80, 107, 0.45);
      .nav-menu-group {
        position: relative;
        margin-top: 10px;
        padding: 26px 0 16px;
        @include clearfix;
        .title-bar {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          transform: translateY(-50%);
          .title {
            padding: 0 6px;
            font-size: 14px;
            line-height: 20px;
            color: $theme-color;
          }
          .line {
            flex: 1;
            height: 1px;
            background-color: $thin-grey-1;
          }
        }
        .nav-menu-group-item {
          float: left;
          line-height: 17px;
          font-size: 12px;
          color: $grey-1;
          padding: 0 6px;
          margin-right: 28px;
          margin-bottom: 8px;
          &:hover {
            color: $theme-color;
            text-decoration: underline;
          }
        }
        .nav-menu-group-item-active {
          float: left;
          line-height: 17px;
          font-size: 12px;
          color: $white;
          padding: 0 6px;
          background: $theme-color;
          margin-right: 28px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.common-panel {
  position: relative;
  box-sizing: border-box;
  background: $white;
  border: 1px solid $thin-grey-2;
  padding: 14px 0;
  .common-content {
    box-sizing: border-box;
    padding: 0 18px;
  }
}

.common-title {
  font-size: $middle-font;
  color: $dark-1;
  font-weight: 500;
  padding: 2px 0 2px 18px;
  margin-bottom: 18px;
  border-left: 4px solid $blue-1;
}

.common-sub-title {
  font-size: $small-font;
  color: $thin-grey-2;
  font-weight: 500;
  margin-left: 4px;
}

.full-panel {
  min-height: calc(100vh - 46px - 28px);
}

.nav-font {
  font-size: $middle-font;
}

.option-item {
  display: inline-block;
  padding: 0 10px;
  position: relative;
  color: $theme-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:first-child {
    padding-left: 0;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $thin-grey-5;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}

.dashed-line {
  box-sizing: border-box;
  border-top: 1px dashed $thin-grey-9;
  height: 1px;
}

.detail-title {
  display: inline-block;
  min-width: 60px;
  margin-right: 10px;
  font-size: $small-font;
  color: $black;
}

.detail-multline {
  vertical-align: top;
  display: inline-block;
  font-size: $small-font;
  color: rgba(0, 0, 0, 0.65);
}

.detail-text {
  font-size: $small-font;
  color: rgba(0, 0, 0, 0.65);
}

/* common component class */

/* layout class */
.container {
  width: 100%;
  min-width: $min-width;
  min-height: 100vh;
}
.menu-link-font {
  color: #fff !important;
}
.backend-container {
  box-sizing: border-box;
  padding: 14px 22px;
  min-height: calc(100vh - 46px);
  min-width: $min-width;
  background: $thin-grey-1;
  @include clearfix;
}
.layout-container {
  position: relative;
  height: calc(100vh - 40px);
  min-width: $min-width;
  background: $white;
  overflow: auto;
  @include clearfix;
}
.second-layout {
  position: absolute;
  left: 160px;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

/* layout class */

/* antd class */
.ant-menu-horizontal {
  line-height: 36px !important;
}
.ant-menu-horizontal {
  .ant-menu-item {
    padding: 0 !important;
    margin: 0 20px 0 0 !important;
  }
}
.ant-menu-submenu-horizontal {
  margin-right: 20px;
}

.ant-form-item > .ant-form-item-label {
  padding: 0 !important;
  float: left;
}

.label-left-item .ant-form-item-label {
  text-align: left;
}
.ant-popover {
  .ant-popover-inner {
    // box-shadow: 0px 0px 6px 0px rgba(80, 117, 210, 1) !important;
    // border: 2px solid rgba(80, 117, 210, 0.29);
    box-shadow: none !important;
    border: none !important;
  }
  .ant-popover-arrow {
    background-color: #fff;
  }
}

/* antd class */

/* custom antd class */
.custom-input-tab .ant-tabs-tab-active {
  background-color: $theme-color !important;
  color: $white !important;
  border-radius: 6px;
}

.custom-input-tab .ant-tabs-tab {
  line-height: 1.2 !important;
}

.custom-input-tab .ant-tabs-left-bar .ant-tabs-tab {
  margin: 0 0 8px 0 !important;
}

.common-modal {
  .ant-modal-header {
    padding: 5px 10px !important;
    background: linear-gradient(
      180deg,
      rgba(167, 195, 226, 1) 0%,
      rgba(230, 239, 253, 1) 100%
    );
    border-radius: 4px 4px 0px 0px;
  }
  .ant-modal-close-x {
    height: 33px;
    line-height: 33px;
    width: 33px;
  }
  .ant-modal-footer {
    padding: 8px 30px 20px 0 !important;
    border-top: none;
    .ant-btn {
      padding: 0 20px;
    }
  }
}

/* custom antd class */

/* ag grid class */
.ag-header-cell-label {
  justify-content: center;
}
.ag-icon-asc,
.ag-icon-desc {
  position: relative;
  top: 4px;
}

.ag-watermark {
  &::before {
    display: none !important;
  }
  &::after {
    display: none !important;
  }
}

.ag-body-horizontal-scroll {
  display: none !important;
}

.ag-center-cols-viewport {
  overflow-x: hidden !important;
}

.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}
/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}
/* ag grid class */
.amap_markers_pop_window {
  border: 0px !important;
  padding: 0px !important;
}
.amap_markers_pop_window::after {
  display: none !important;
}
.css-yrspe {
  z-index: 1000 !important;
}
.css-1rbq2qy {
  z-index: 2000 !important;
}
.Tasks_steps-content__2z_BD {
  border: none !important;
  background: none !important;
  overflow: hidden;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgba(0, 143, 255, 0.3) !important;
}

/* antd-table class */
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}
.ant-table-thead > tr > th {
  font-size: 13px !important;
}

.ant-table-small
  > .ant-table-content
  > .ant-table-header
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-header
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-header
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 4px 12px !important;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-table-small
  > .ant-table-content
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr {
  background-color: #f9fbfc !important;
}
.ant-table-row {
  font-size: 12px !important;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
  float: left;
  width: 100%;
  height: 1000px;
}
.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}
.customResizable {
  .react-resizable-handle {
    position: absolute;
    width: 100%;
    height: 10px;
    bottom: 0;
    cursor: s-resize;
    z-index: 1;
  }
}
.ant-btn-sm {
  font-size: 12px !important;
}

.site-custom-tab-bar {
  z-index: 1;
  background: #fff;
}
.ant-descriptions-item-content {
  font-size: 12px !important;
}
.ant-descriptions-item-label {
  font-size: 12px !important;
}
.customTab {
  padding-left: 20px !important;
}
.amap-info-content {
  padding: 0 !important;
}
.info_button {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 24px;
  padding: 0 7px;
  font-size: 12px;
  border-radius: 4px;
  margin: 10px 2px;
  float: right;
  cursor: pointer;
}
.info_button:hover {
  color: #fff;
}
.info_container {
  line-height: 25px;
}
.info_title {
  height: 50px;
  line-height: 50px;
  background: rgba(249, 249, 250, 1);
  border-bottom: solid 1px rgba(0, 0, 0, 0.06);
  padding-left: 20px;
  font-weight: bold;
}
.info_content {
  padding: 10px 15px 15px 20px;
  overflow-y: auto;
  max-height: 300px;
}
.amap-info-close {
  top: 17px !important;
}
.ant-tree .ant-tree-treenode {
  height: 30px !important;
}
.ant-tree .ant-tree-node-content-wrapper {
  height: 24px !important;
  line-height: 24px !important;
}
.searchItem:hover {
  background-color: #15cbe2;
  color: #fff;
}
.searchItemCC:hover {
  background-color: #bd192d;
  color: #fff;
}
.searchItem-active {
  background-color: #15cbe2;
  color: #fff;
}
.searchItem-active-cc {
  background-color: #bd192d;
  color: #fff;
}
.ant-tabs .ant-tabs-right-content {
  padding-right: 0 !important;
}
/***********divider************/
.ant-divider-with-text-left::before {
  top: 50%;
  width: 30% !important;
}
.ant-divider-inner-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #282b2d;
}
.roleSelect {
  background-color: #e6f7ff;
  color: #1890ff;
}
.ant-dropdown-menu-item-disabled > a,
.ant-dropdown-menu-submenu-title-disabled > a {
  color: #cccccc !important;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
  min-width: 90px !important;
}

.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  width: 90% !important;
}
// timeline 样式调整
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: 100px !important;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
  left: 115px !important;
}
.ant-timeline.ant-timeline-label
  .ant-timeline-item-left
  .ant-timeline-item-content {
  left: 110px !important;
  width: 80% !important;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  left: 115px !important;
}

.react-images__view-wrapper {
  text-align: center !important;
}
.maploading {
  display: inline-block;
  width: 30px;
  height: 30px;
  color: #409eff;
  vertical-align: middle;
  pointer-events: none;
  border-top: 0.4em solid currentcolor;
  border-right: 0.4em solid transparent;
  -webkit-animation: maploading 1s linear infinite;
  animation: maploading 1s linear infinite;
  border-radius: 100%;
  position: relative;
}
@-webkit-keyframes maploading {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes maploading {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading_content {
  width: 200px;
  height: 180px;
  .maploading {
    margin: 70px 0 0 80px;
  }
}
.ant-form-item-label {
  white-space: break-spaces !important;
}
.ant-form-item-label > label {
  display: inline-block !important;
}
.ant-cascader-menu-item {
  width: 150px !important;
}
