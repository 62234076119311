/*Length*/
$min-width: 1000px;
/*Length*/

/*Color*/
$main-theme: #25b864;
$theme-color: #466BC8;
$white: #fff;
$black: #000;
$thin-grey-1: #F0F2F5;
$thin-grey-2: #C1D0D8;
$thin-grey-3: #DCE6EB;
$thin-grey-4: #D8E3EC;
$thin-grey-5: #D8D8D8;
$thin-grey-6: #97A0AD;
$thin-grey-7: #C7DBED;
$thin-grey-8: #9FAAC1;
$thin-grey-9: #CDD3DE;
$thin-grey-10: #E9EFF7;
$thin-grey-11: #8EA2B4;

$grey-1: #43506B;

$dark-1: #333333;
$dark-2: #1C2D3E;
$dark-3: #4E6886;

$blue-1: #5075D2;
$blue-2: #60B4FF;
$blue-3: #466BC8;
$blue-4: #F4F8FE;

$yellow-1: #FFA339;
$yellow-2: #FFD222;

$red-1: #EB5079;


/*Color*/

/*Font*/
$small-font: 12px;
$middle-font: 14px;
$big-font: 26px;
/*Font*/

/*zIndex*/
$zindex-level-1: 10001;
/*zIndex*/
